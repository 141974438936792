<template>
  <div class="module-wrapper">
    <data-tools :token="token"></data-tools>
  </div>
</template>

<script>


import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { getCookie } from '~assets/scripts/utils/dom'
import { ERPCOOKIENAME } from '@scripts/config'

export default {
  name: 'MDataTools',
  mixins: [getCommonDataMixin],
  components: {
  },
  data() {
    return {
      token: ''
    }
  },

  props: {
    initData: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.onInit()
  },
  methods: {
    onInit: function () {
      const token = getCookie(ERPCOOKIENAME, this.$store)
      this.token = token.substring(6, token.length)
    },
  }
}
</script>

<style lang="scss">
.c-DataTools {
  .znl-echarts {
    .echarts {
      height: 380px;
    }
  }
  .znl-grid-component .znl-content {
    min-height: 200px;
  }
}
.p-ZnlAdo .znl-ZnlAdo-upload {
  left: 110px !important;
}
</style>

