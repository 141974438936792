
<template>
  <znl-layout-spa>
    <m-data-tools></m-data-tools>
  </znl-layout-spa>
</template>

<script>


import MDataTools from '@c_modules/Industry/DataTools'

export default {
  name: 'VDataTools',
  components: {
    MDataTools
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>

